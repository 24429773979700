import { TippedInfoDetail } from 'common/interfaces/api';
import LabelButton from 'components/common/LabelButton';
import RoundIcon from 'components/common/RoundIcon';
import { tippingPackageData } from 'common/utils';
import Link from 'next/link';
import styles from './TippedItem.module.scss';
import classNames from 'classnames';

type TippedItemProps = {
  tippedInfo: TippedInfoDetail;
  className?: string;
  enableLink?: boolean;
};

const TippedItem = ({
  tippedInfo,
  className,
  enableLink = true,
}: TippedItemProps): JSX.Element => {
  if (!tippedInfo.tipping_package) return <></>;
  const tipPaymentData = tippingPackageData(tippedInfo);
  return (
    <Link href={`/${tippedInfo.user?.username}`}>
      <a
        style={!tippedInfo.user || !enableLink ? { pointerEvents: 'none' } : {}}
      >
        <LabelButton
          text={
            <>
              <div className={styles.tippedIcon}>
                <RoundIcon user={tippedInfo.user} width={20} height={20} />
              </div>
              <span>¥{tipPaymentData.amount}</span>
            </>
          }
          className={classNames(className)}
          type={
            `tip-${tippedInfo.tipping_package.id}` as
              | 'tip1'
              | 'tip2'
              | 'tip3'
              | 'tip4'
              | 'tip5'
          }
        />
      </a>
    </Link>
  );
};

export default TippedItem;
